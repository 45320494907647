import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { inject, Injectable, PLATFORM_ID } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class IntercomService {
  private window = inject(DOCUMENT).defaultView || window;
  private platformId = inject(PLATFORM_ID);
  private get intercom() {
    if (isPlatformBrowser(this.platformId)) {
      return this.window.Intercom;
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }

  initialized() {
    return this.window.Intercom ? true : false;
  }

  init() {
    if (!this.initialized()) {
      const script = this.window.document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = `https://widget.intercom.io/widget/${environment.intercom.appId}`;
      const body = this.window.document.getElementsByTagName("body")[0];
      body.append(script);
    }
  }

  boot() {
    this.intercom("boot", {
      app_id: environment.intercom.appId,
      created_at: new Date()
    });
  }

  update(data: IntercomNs.IntercomSettings) {
    this.intercom("update", { ...data, created_at: new Date() });
  }

  shutdown() {
    this.intercom("shutdown");
  }

  show() {
    this.intercom("update", { hide_default_launcher: false });
  }

  hide() {
    this.intercom("update", { hide_default_launcher: true });
  }

  open() {
    this.intercom("showNewMessage");
  }
}
